"use strict";

const Forms = () => {
    const init = () => {
       $(document).ready(function() {
           // Init datepickers
           $('.datepicker').datepicker({
               dateFormat: "dd-mm-yy",
               minDate: new Date(),
               onClose: function(date) {
                   if ( date == "" ) {
                       $(this).parents('.form-group').removeClass('filled');
                   } else {
                       $(this).parents('.form-group').addClass('filled');
                   }
               }
           });
           $( ".datepicker" ).datepicker('option','beforeShowDay',function(date){
               var td = date.getDay();
               var ret = [(date.getDay() != 0 && date.getDay() != 6),'',(td != 'Sat' && td != 'Sun')?'':'only on workday'];
               return ret;
           });

           /**
            * Handle input focus to animate labels
            */
           $('input, textarea').focus(function(){
               $(this).parents('.form-group').addClass('focused');
           });
           $('input, textarea').blur(function(){
               var inputValue = $(this).val();
               if ( inputValue == "" ) {
                   $(this).parents('.form-group').removeClass('filled');
                   $(this).parents('.form-group').removeClass('focused');
               } else {
                   $(this).parents('.form-group').addClass('filled');
               }
           });
           $('select').change(function() {
               var inputValue = $(this).val();
               if ( inputValue == "" ) {
                   $(this).parents('.form-group').removeClass('filled');
               } else {
                   $(this).parents('.form-group').addClass('filled');
               }
           });

           // Only enable the current insurance expiry if 'Yes' is selected
           $("select[name='currentInsurance']").change(function() {
               var inputValue = $(this).val();
               if ( inputValue == "yes" ) {
                   $('.disabled').removeClass('disabled');
               }
           });

           // Make all 'disabled' values actually disabled - not available in form builder
           $("option:selected").each(function() {
                let $option = $(this);
                if ($option.val() === 'disabled') {
                    $option.attr('disabled', true);
                    $option.attr('selected', true);
                }
           });

           // Add '*' to required fields
           $('input:required, select:required, textarea:required').each(function() {
                let $label = $(this).parents('.form-group').find('label');
                $label.addClass('required');
           });
       });
    };

    const onSuccess = (form) => {
        $(form).find('.alert-success').addClass('show');
        $(form).find('.alert-danger').alert('close');
    };

    const onError = (form) => {
        $(form).find('.alert-danger').addClass('show');
    };

    return {
        init:init,
        onSuccess:onSuccess,
        onError:onError
    };
};

export default Forms;