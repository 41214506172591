"use strict";

const SideNav = () => {
    const init = () => {
        $(document).ready(function () {
            onScroll();
        });
    };

    const onScroll = () => {
        let $nav = $('.side-nav-static');

        if ($nav.length) {
            const updateNav = function() {
                if ($(document).scrollTop() > 150) {
                    $nav.addClass('fixed');
                } else {
                    $nav.removeClass('fixed');
                }
            };

            $(window).scroll(function() {
                 updateNav();
            });

            updateNav();
        }
    };

    return {
        init:init,
    };
};

export default SideNav;