"use strict";

const Team = () => {
    const init = () => {
        $(document).ready(function () {
            modal();

            $('.modal').on('show.bs.modal', function() {
                $('html').css('overflow', 'hidden');
            });
            $('.modal').on('hide.bs.modal', function() {
                $('html').css('overflow', 'initial');
            });
        });
    };

    const modal = () => {
        let $next = $('.modal .next'),
            $prev = $('.modal .prev');

        $next.click(function() {
            let $thisModal = $(this).parents('.modal');
            let $nextModal = $('#member-modal-' + $(this).data('target'));

            $thisModal.modal('hide');
            $nextModal.modal('show');

            setTimeout(() => {
                $('body').addClass('modal-open');
                $('body').css('padding-right', 15);
            }, 500);
        });

        $prev.click(function() {
            let $thisModal = $(this).parents('.modal');
            let $prevModal = $('#member-modal-' + $(this).data('target'));

            $thisModal.modal('hide');
            $prevModal.modal('show');

            setTimeout(() => {
                $('body').addClass('modal-open');
                $('body').css('padding-right', 15);
            }, 500);
        });
    };

    return {
        init:init,
    };
};

export default Team;