"use strict";

const gaEvents = () => {
    const init = () => {
      pdfDownload();
      telClick();
      mailToClick();
      inputChange();
      textAreaChange();
      selectChange();
      formSubmit();
      iframeLoad();
    };

    const findParam = (name) => {
      let result = null,
          tmp = [];
      location.search
          .substr(1)
          .split("&")
          .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === name) result = decodeURIComponent(tmp[1]);
          });
      return result;
    };

    const pdfDownload = () => {
      $('a[target="_blank"]').click(function(){
        var str = $(this).attr('href');
        if (str.indexOf(url) >= 0){
          ga('send', 'event', 'MEDIA', 'DOWNLOADED', 'pdf downloaded ' + $(location).attr('pathname') + $(location).attr('search'), 0);
        }else{
          //offsite link
        }
      });
    };

    const telClick = () => {
      $('a[href^="tel:"]').click(function(){
        var t = $(this).parent();
        while(!t[0].hasAttribute('id') && !t.is('body')){
          t = t.parent();
        }
        if(t[0].hasAttribute('id')){
          var id = t.attr('id');
        }else{
          var id = 'body';
        }
        ga('send', 'event', 'ENQUIRY', 'ENGAGED', id+' tel '+$(location).attr('pathname')+$(location).attr('search'), 0);
      });
    };

    const mailToClick = () => {
      $('a[href^="mailto:"]').click(function(){
        var t = $(this).parent();
        while(!t[0].hasAttribute('id') && !t.is('body')){
          t = t.parent();
        }
        if(t[0].hasAttribute('id')){
          var id = t.attr('id');
        }else{
          var id = 'body';
        }
        ga('send', 'event', 'ENQUIRY', 'ENGAGED', id + ' mailto ' + $(location).attr('pathname') + $(location).attr('search'), 0);
      });
    };

    const inputChange = () => {
      $('form input').change(function(){
        let context;
          let t = $(this).parent();
          while(!t.is('form')){
              t = t.parent();
          }
          let action = t.attr('action');
          action = action.split('?');
          if(action.length > 1){
              context = action[1];
          }else{
              context = action[0];
          }
        ga('send', 'event', 'ENQUIRY', 'CHANGED', $(this).attr('name') + ' input changed on form ' + context + ' ' + $(location).attr('pathname') + $(location).attr('search'), 0);
      });
    };

    const textAreaChange = () => {
      $('form textarea').change(function(){
          let context;
          let t = $(this).parent();
          while(!t.is('form')){
              t = t.parent();
          }
          let action = t.attr('action');
          action = action.split('?');
          if(action.length > 1){
              context = action[1];
          }else{
              context = action[0];
          }
        ga('send', 'event', 'ENQUIRY', 'CHANGED', $(this).attr('name') + ' textarea changed on form ' + context + ' ' + $(location).attr('pathname') + $(location).attr('search'), 0);
      });
    };

    const selectChange = () => {
      $('form select').change(function(){
          let context;
          let t = $(this).parent();
          while(!t.is('form')){
              t = t.parent();
          }
          let action = t.attr('action');
          action = action.split('?');
          if(action.length > 1){
              context = action[1];
          }else{
              context = action[0];
          }
        ga('send', 'event', 'ENQUIRY', 'CHANGED', $(this).attr('name') + ' select changed on form ' + context + ' ' + $(location).attr('pathname') + $(location).attr('search'), 0);
      });
    };

    const formSubmit = () => {
      $('form').submit(function(){
        let context;
        let action = $(this).attr('action');
        action = action.split('?');
        if(action.length > 1){
          context = action[1];
        }else{
          context = action[0];
        }
        ga('send', 'event', 'ENQUIRY', 'SUBMITTED', 'form submitted ' + context + ' ' + $(location).attr('pathname') + $(location).attr('search'), 0);
      });
    };

    const iframeLoad = () => {
      $('iframe').on('load', function(){
        if($(this).attr('src') != ''){
          ga('send', 'event', 'MEDIA', 'VIEWED', 'iframe viewed ' + $(this).attr('src') + ' ' + $(location).attr('pathname') + $(location).attr('search'), 0);
        }
      });
    };

    const onFormSuccess = (form_id) => {
      // Check for successfull form submissions
      ga('send', 'event', 'ENQUIRY', 'SUBMITTED', 'form submitted ' + form_id + ' ' + $(location).attr('pathname') + $(location).attr('search'), 0);
    };

    return {
      init: init,
      onFormSuccess: onFormSuccess
    };
};

export default gaEvents;