window.$ = window.jQuery = require('jquery');
require('jquery-ui-dist/jquery-ui');
require ('popper.js');
require ('bootstrap');
import gaEvents from './vendor/ga-events';
import sidenav from './_sidenav';
import forms from './_forms';
import services from './_services';
import partners from './_partners';
import team from './_team';

$(function() { // Shorthand for $( document ).ready()
    "use strict";
    // Your js script is below this line
    // --------------------------------------------------------------------- //
    window.SideNav = new sidenav();
    SideNav.init();

    window.GA = new gaEvents();
    GA.init();

    window.Forms = new forms();
    Forms.init();

    window.Services = new services();
    Services.init();

    window.Partners = new partners();
    Partners.init();

    window.Team = new team();
    Team.init();
});