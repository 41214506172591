"use strict";

const Services = () => {
    const init = () => {
        $(document).ready(function () {
            homepage();
        });
    };

    const homepage = () => {
          let $serviceButtons = $('.service-button');

          // Hide all service images
          function hideServiceImages() {
              $('#home-services .service-image').fadeOut(200);
          }

          // Hide all service content
          function hideServiceContent() {
              $('#home-services .service-content').fadeOut(200);
          }

          // Bind click event to service buttons on homepage section
          $serviceButtons.each(function() {
              let $button = $(this);

              // On click, hide all services and then show this one
              $button.click(function(e) {
                  e.stopPropagation();

                  hideServiceImages();
                  hideServiceContent();

                  // Update URL
                  let slug = $button.data('service-slug');
                  window.location = '#' + slug;

                  if ($(window).width() < 768) {
                      window.location.href = '/services/' + slug;
                  } else {
                      // Change active button
                      $('.service-button.active').removeClass('active');
                      $button.addClass('active');

                      // Show this content
                      let serviceId = $button.data('service');
                      $('#service-image-' + serviceId).delay(195).fadeIn();
                      $('#service-content-' + serviceId).delay(195).fadeIn();
                  }
              });
          });

          // Finally check for hash values on load
          if (window.location.hash) {
              let slug = window.location.hash.replace('#', ''),
                  $services = $('#home-services');
              let $button = $services.find(`[data-service-slug='${slug}']`);
              if ($button.length) {
                  $button.click();
                  $([document.documentElement, document.body]).animate({
                      scrollTop: $services.offset().top
                  }, 500);
              }
          }
    };

    return {
        init:init,
    };
};

export default Services;