"use strict";

require('slick-carousel');

const Partners = () => {
    const init = () => {
        $(document).ready(function () {
            slider();
        });
    };

    const slider = () => {
        if ($('#partners-slider').length) {
            let $sliderDesktop = $('.slider.desktop');
            $sliderDesktop.slick({
                slidesToShow: 9,
                infinite: true,
                slidesToScroll: 3,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 3,
                            infinite: true,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            infinite: true,
                        }
                    }
                ]
            });
            let $sliderMobile = $('.slider.mobile');
            $sliderMobile.slick({
                slidesToShow: 1,
                infinite: true,
                slidesToScroll: 1,
            });

            let $prev = $('#partners, #insurance-partners').find('.controls .prev');
            $prev.click(function() {
                $sliderDesktop.slick('slickPrev');
                $sliderMobile.slick('slickPrev');
            });

            let $next = $('#partners, #insurance-partners').find('.controls .next');
            $next.click(function() {
                $sliderDesktop.slick('slickNext');
                $sliderMobile.slick('slickNext');
            });
        }
    };

    return {
        init:init,
    };
};

export default Partners;